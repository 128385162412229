.App {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.grand-parent {
  /* 박스 스타일링 */
  height: 500px;
  border: 5px dotted lightsalmon;
  /* padding : border와 content사이에 주는 간격 */
  padding: 50px;
}

.parent {
  /* 박스 스타일링 */
  height: 500px;
  border: 5px dotted lightslategray;
  padding: 10px;
}

.child {
  /* 박스 스타일링 */
  width: 150px;
  height: 100px;
  border: 3px dotted crimson;
  border-radius: 12px;
  background-color: lightyellow;

  /* 텍스트 정렬 */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.naverMap {
  width: 100%;
  height: 100%;
  position: relative;
}

/*  position: relative
-> element가 원래 본인 위치 기준으로 자리를 잡음 */
.container {
  display: flex;
  align-self: center;
  max-width: 500px;
  height: 140px;
  padding: 8px;
  width: calc(100% - 24px);
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 20px;
  box-shadow: 0 0 13px 0 rgba(0, 10, 0, 0.1);
  border-radius: 15px;
  background: white;
  z-index: 2;
}

.container > img {
  /*16:9*/
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 15px;
}

.home-logo {
  position: absolute;
  top: 12px;
  max-width: 120px;
  left: 50%;                     /* 왼쪽으로부터 50%의 위치에 요소를 배치 */
  transform: translateX(-50%);   /* 요소의 너비의 절반만큼 왼쪽으로 이동 */
}

.home-logo > img {
  width: 100%;
}

.send-button {
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: 0;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.text-container > h1 {
  font-size: 16px;
  font-weight: 800;
  color: #000;
}

.text-container > h2 {
  font-size: 12px;
  color: #737373;
  margin-top: 6px;
  text-align: left;
  overflow: hidden;              /* 컨텐츠가 넘칠 때 숨깁니다 */
  display: -webkit-box;          /* Webkit 기반의 박스 모델을 사용합니다 */
  -webkit-box-orient: vertical;  /* 박스의 방향을 수직으로 설정합니다 */
  -webkit-line-clamp: 2;         /* 텍스트 라인 수를 2줄로 제한합니다 */
  white-space: normal;           /* 텍스트가 여러 줄로 나뉘도록 합니다 */
  text-overflow: ellipsis;
  flex-grow: 1;
}

.text-container > h3 {
  align-self: end;
  color: #a6a6a6;
  margin-top: 6px;
  font-size: 8px;
}

.text-address {
  font-size: 10px;
  color: #737373;
}

.modal-logo {
  width: 200px;
  margin: 0 auto;
}

.modal-content {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
}

.modal-content > p {
  margin-bottom: 30px;
}

.modal-content > Button {
  margin-top: 8px;
}

.modal-ok-button {
  width: 100%;
}

.detail-modal {
  position: absolute;
  z-index: 5;
}

@media (min-width: 480px) {
  .container {
    display: flex;
    align-self: center;
    max-width: 700px;
    height: 220px;
    padding: 12px;
    width: calc(100% - 24px);
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 20px;
    box-shadow: 0 0 13px 0 rgba(0, 10, 0, 0.1);
    border-radius: 15px;
    background: white;
  }

  .text-container {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .text-container > h1 {
    font-size: 20px;
  }
  .text-container > h2 {
    font-size: 16px;
    margin-top: 12px;
    -webkit-line-clamp: 4;         /* 텍스트 라인 수를 2줄로 제한합니다 */
  }

  .text-container > h3 {
    margin-top: 4px;
    font-size: 12px;
  }

  .text-address {
    font-size: 14px;
  }

  .home-logo {
    max-width: 240px;
  }

  .modal-logo {
    width: 300px;
  }

  .send-button {
    top: 24px;
    right: 24px;
  }

}

